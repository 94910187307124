import React from 'react'

import {
  Center,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

// theme here https://bootswatch.com/lux/ currently off in application layout
//
export default function Home() {
  return (
    <Container>
      <Stack
        spacing="3"
      >
        <Center
          axis="horizontal"
        >
          <Heading
            as="h2"
            size="lg"
          >
            Empower Your Shopify Store with Utility Blade
          </Heading>
        </Center>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Simplify Your Daily Tasks: Discover the Power of Utility Blade
          </Heading>
        </Center>
        <Text>
          Utility Blade isn't just a tool; it's a solution meticulously crafted to simplify and streamline your daily tasks. It's the answer to those countless hours spent tediously changing hundreds of prices to initiate a sale, only to revert them back to their original values a week later. It's your go-to resource for automating and scheduling those time-consuming and costly editing tasks that have been holding you back from realizing your store's full potential.
        </Text>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Unlock Automation Excellence with Utility Blade for Shopify
          </Heading>
        </Center>
        <Text>
          Imagine the power of automation at your fingertips. With Utility Blade, you can effortlessly create and execute tasks that operate on sets of products and various resources within your Shopify store. Whether you need to find and replace text in all your home page products' titles or adjust the prices of items within your 'Trending now' collection to 80% of their current value, Utility Blade has got you covered.
        </Text>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Enhanced Control and Flexibility: Utility Blade's Advanced Automation Features
          </Heading>
        </Center>
        <Text>
          But that's not all! Utility Blade goes beyond simple automation. It empowers you with the ability to revert any changes made by a task after a specific time has elapsed. This feature ensures that you maintain complete control and flexibility over your store's operations. Additionally, you can schedule tasks to run at your convenience, even in the future, and repeat them on any interval you desire. The possibilities are as vast as your imagination, giving you the freedom to tailor your store's management to your unique needs.
        </Text>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Revolutionize Your Shopify Store: Unleash Efficiency with Utility Blade
          </Heading>
        </Center>
        <Text>
          The time to supercharge your Shopify store's efficiency is now! Don't hesitate to take advantage of Utility Blade's incredible capabilities. Installing Utility Blade today is not just a wise choice; it's a strategic move that can revolutionize the way you manage your online business. And here's the best part: Utility Blade offers a completely free plan, allowing you to explore its full potential at no cost. There's no reason to delay - experience the transformational power of Utility Blade for yourself and witness how it can propel your store towards unprecedented success. Your time is precious; make every moment count with Utility Blade!
        </Text>
      </Stack>
    </Container>
  )
}
