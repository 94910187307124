import React from 'react'

import {
  Center,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

export default function Pricing() {
  return (
    <Container>
      <Stack
        spacing="3"
      >
        <Center
          axis="horizontal"
        >
          <Heading
            as="h2"
            size="lg"
          >
            Pricing options available for Shopify stores of all varieties!
          </Heading>
        </Center>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            100% Free Plan Available!
          </Heading>
        </Center>
        <Text>
          Enjoy the full capabilities of Utility Blade at absolutely no cost, with the freedom to use it up to five times every month for bulk editing of an extensive array of products and variants. This means you can harness the efficiency of Utility Blade without any expense, ultimately saving both time and money that would have been devoted to manual product edits.
        </Text>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Unlock Savings and Benefits with a Subscription!
          </Heading>
        </Center>
        <Text>
          For those who use Utility Blade regularly, a subscription can be a game-changer for your store. Our subscription plans offer the most cost-effective approach to using Utility Blade, providing you with a generous allocation of application use along with exclusive premium features and support not available in our free plan. Subscribing today grants you the confidence and capacity to elevate your usage to power user levels, taking your store to new heights.
        </Text>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Tailored Pricing and Subscriptions: Utility Blade Fits Your Needs
          </Heading>
        </Center>
        <Text>
          At Utility Blade, we understand that every e-commerce venture is unique, with varying budgets and requirements. That's why we offer an extensive array of pricing and subscription options designed to accommodate businesses at all stages of their journey. Whether you're launching a new venture, managing a mid-sized online store, or overseeing a sprawling Shopify Plus network with tens of thousands of products, we have the perfect solution for you. With Utility Blade, your specific needs are our priority, ensuring you can optimize your operations and grow your business efficiently.
        </Text>
      </Stack>
    </Container>
  )
}

