import React from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  APPLICATION_NAME
} from '../constants/application'

import {  
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex, 
  Heading,
  HStack, 
  Image, 
  Link as ChakraLink,
  VStack,
  chakra, 
  useDisclosure, 
} from '@chakra-ui/react'
import Logo from '../logo.png'
import {  
  IoMdMenu, 
} from 'react-icons/io'

const menuItems = <>
  <Link
    to="/"
  >
    <Button variant="nav">Home</Button>
  </Link>
  <Link 
    to="/pricing"
  >
    <Button variant="nav">Pricing</Button>
  </Link>
  <Link 
    to="/support"
  >
    <Button variant="nav">Support</Button>
  </Link>
</>

export default function Navigation() {
  return (
    <chakra.header id="header">
      <Flex
        w="100%"
        px="6"
        py="5"
        align="center"
        justify="space-between"
      >
        <HStack>
          <Image src={Logo} h="32px" />
          <Heading
            size="md"
            as="h1"
          >
            { APPLICATION_NAME }
          </Heading>
        </HStack>
        <HStack 
          as="nav" 
          spacing="5"
          display={['none', 'none', 'flex', 'flex']}
        >
          { menuItems }
        </HStack>

        <HStack>
          <ChakraLink
            href="https://apps.shopify.com/utility-blade"
          >
            <Button>
              Install now!
            </Button>
          </ChakraLink>
          <MobileNavigation />
        </HStack>
        
      </Flex>
    </chakra.header>
  );
}

const MobileNavigation = () => {
  const { 
    isOpen, 
    onOpen, 
    onClose 
  } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <Flex
      display={['flex', 'flex', 'none', 'none']}
    >
      <Button 
        ref={ btnRef }
        onClick={ onOpen }
      >
        <IoMdMenu size="26" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Menu
          </DrawerHeader>
          <DrawerBody>
            <VStack
              align="left"
            >
              { menuItems }
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
