import React from 'react'
import {
  Center,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

export default function Support() {
  return (
    <Container>
      <Stack
        spacing="3"
      >
        <Center
          axis="horizontal"
        >
          <Heading
            as="h2"
            size="lg"
          >
            Exceptional Support for Your Shopify Store
          </Heading>
        </Center>
        <br/>
        <Center
          axis="horizontal"
        >
          <Heading
            as="h3"
            size="md"
          >
            Empowering Your Store with Utility Blade: Our Dedicated Support Team Is Here to Assist You
          </Heading>
        </Center>
        <Text>
          Utility Blade is a robust tool meticulously designed to enhance the efficiency and seamlessness of your store operations. However, in the dynamic world of e-commerce, hiccups can occur. When these challenges arise, it's reassuring to have a reliable support team by your side to help you navigate through any issues. Our dedicated support staff is readily available to swiftly assist you, ensuring that you can quickly overcome any obstacles and continue running your store smoothly. Your success is our priority, and we're here to support you every step of the way.
        </Text>
      </Stack>
    </Container>
  )
}

