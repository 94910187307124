import React from 'react'
import {
  createRoot,
} from 'react-dom/client'
import { 
  BrowserRouter,
} from 'react-router-dom'
import {
  ChakraProvider,
} from '@chakra-ui/react'
import ApiProvider from './api_provider'
import {
  Theme,
} from './theme'

const root = createRoot( document.getElementById('root') )
root.render(
  <ChakraProvider 
    theme={ Theme }
  >
    <BrowserRouter>
      <ApiProvider />
    </BrowserRouter>
  </ChakraProvider>
)
