import { extendTheme } from '@chakra-ui/react'

export const Theme = extendTheme({
  sizes: {
//    container: {
//      sm: '640px',
//      md: '768px',
//      lg: '1024px',
//      xl: '1280px',
//    },
  },
})
