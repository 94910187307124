import React, { 
} from 'react'
//import AppRouter from './app_router'
//import { 
//  ApolloClient, 
//  ApolloProvider,
//  HttpLink, 
//  InMemoryCache, 
//  useQuery as useApolloQuery,
//} from '@apollo/client'
import AppRouter from './app_router'

export default function ApiProvider() {
  // there is a problem with the csrf token, not sure why, seems like it's not the same as the comparison...
  //const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content')
  //const client = new ApolloClient({
  //  uri: 'https://dev.utilitybladeapp.com/graphql',
  //  link: new HttpLink({
  //    credentials: 'same-origin',
  //    headers: {
  //      'Content-Type': 'application/json',
  //      'X-CSRF-Token': csrfToken,
  //    },
  //  }),
  //  cache: new InMemoryCache()
  //})

  return(
    //<ApolloProvider client={client}>

      <AppRouter />

    //</ApolloProvider>
  )
}

