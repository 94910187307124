import React from 'react'
import {
  Route,
  Routes,
} from 'react-router-dom'
import Navigation from './shared/navigation'
import Footer from './shared/footer'
import Home from './home'
import Pricing from './pricing'
import Support from './support'
import NotFound from './not_found'
import "./styles.css";
import ReactGA from 'react-ga4'
ReactGA.initialize("G-8126696BRJ")

export const HOME_ROUTE = '/'
export const PRICING_ROUTE = "/pricing"
export const SUPPORT_ROUTE = "/support"

export default function AppRouter() {
  return (
    <>
      <Navigation />

      <Routes>
        <Route path={ HOME_ROUTE } element={ <Home /> } />
        <Route path={ PRICING_ROUTE } element={ <Pricing /> } />
        <Route path={ SUPPORT_ROUTE } element={ <Support /> } />

        <Route element={ <NotFound /> } />
      </Routes>

      <Footer />
    </>
  )
}
